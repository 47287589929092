import React from 'react';

function Modal({ isOpen, onClose, title, children, showSaveButton, onSave }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>

            {/* Modal */}
            <div className="bg-primary p-8 w-full max-w-md m-auto rounded shadow-lg z-10">
                <h3 className="text-2xl mb-4">{title}</h3>
                {children}
                {showSaveButton && (
                    <button
                        className="transition-all duration-300 transform bg-blue-500 text-[#fff1d6] border-4 border-blue-500 hover:border-4 hover:border-blue-500 hover:bg-primary hover:text-blue-500 px-4 py-2 mt-4 mr-4"
                        onClick={onSave}
                    >
                        Save
                    </button>
                )}
                <button
                    className="transition-all duration-300 transform bg-red-500 border-4 border-red-500 hover:border-4 hover:border-red-500 hover:bg-primary hover:text-red-500 text-[#fff1d6] px-4 py-2 mt-4"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
}

export default Modal;
