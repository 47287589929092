import React from 'react';
import Modal from './Modal';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { ResponsiveContainer } from 'recharts';


import './App.tailwind.css';

function App() {

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({
    date: new Date().toISOString().slice(0, 16),  // Default to the current date and time
    amount: ''
  });

  const [currentPage, setCurrentPage] = React.useState(1);


  const [records, setRecords] = React.useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Add the new record to the records array
    const newRecords = [...records, formData];

    // Save to local storage
    localStorage.setItem('cannabisRecords', JSON.stringify(newRecords));

    // Update state
    setRecords(newRecords);

    // Clear form data
    setFormData({
      date: '',
      amount: ''
    });

    // Close modal
    setIsModalOpen(false);
  };

  React.useEffect(() => {
    const storedRecords = localStorage.getItem('cannabisRecords');
    if (storedRecords) {
      setRecords(JSON.parse(storedRecords));
    }
  }, []);

  const handleNowClick = () => {
    setFormData(prev => ({ ...prev, date: new Date().toISOString().slice(0, 16) }));
  };


  const handleRecentRecordClick = (record) => {
    setFormData(prev => ({ ...prev, amount: record.amount }));
  };

  const isThisWeek = (date) => {
    const now = new Date();
    const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
    return date >= startOfWeek;
  };

  const isThisMonth = (date) => {
    const now = new Date();
    return date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth();
  };

  const thisWeekRecords = records.filter(record => isThisWeek(new Date(record.date)));
  const thisMonthRecords = records.filter(record => isThisMonth(new Date(record.date)));

  const biggestThisWeek = Math.max(...thisWeekRecords.map(record => parseFloat(record.amount)));
  const smallestThisWeek = Math.min(...thisWeekRecords.map(record => parseFloat(record.amount)));
  const totalThisMonth = thisMonthRecords.reduce((acc, curr) => acc + parseFloat(curr.amount), 0);

  const clearData = () => {
    localStorage.removeItem('cannabisRecords');
    setRecords([]);
  };

  const addDemoData = () => {
    const demoRecords = [];
    const today = new Date();

    for (let i = 0; i < 10; i++) {
      const randomDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
      const randomAmount = (Math.random() * 5).toFixed(2); // Random amount between 0 and 5 grams
      demoRecords.push({
        date: randomDay.toISOString().slice(0, 16),
        amount: randomAmount
      });
    }

    const updatedRecords = [...records, ...demoRecords];
    localStorage.setItem('cannabisRecords', JSON.stringify(updatedRecords));
    setRecords(updatedRecords);
  };

  const recordsPerPage = 3;
  const totalPages = Math.ceil(records.length / recordsPerPage);
  const displayedRecords = records.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);



  return (
    <div className="min-h-screen p-8 font-mono bg-primary text-primary accent-[#0000ff] selection:bg-[#0000ff] selection:text-[#fff1d6]">
      <header className="mb-12">
        <h1 className="text-4xl font-bold tracking-wider">THCandMe</h1>
        <p className="mt-4 text-gray-600">A straightforward tool to monitor and reduce your cannabis usage.</p>
      </header>

      <main className="">
        <div className="flex flex-wrap p-2 md:p-4">
          <div className="bg-white p-8 border border-gray-400 w-full md:w-1/2 mb-4">
            <h2 className="text-2xl mb-4">Track Your Usage</h2>
            <p className="text-gray-700">Input your daily cannabis consumption and visualize your patterns over time. Knowledge is power.</p>
          </div>

          <div className="bg-white p-8 border border-gray-400 w-full md:w-1/2 mb-4">
            <h2 className="text-2xl mb-4">Reduce with Incentives</h2>
            <p className="text-gray-700">Earn badges, maintain streaks, and challenge yourself to cut down. Get rewarded for your efforts.</p>
          </div>

          <div className="bg-white p-8 border border-gray-400 w-full md:w-1/2 mb-4">
            <h2 className="text-2xl mb-4">100% Confidential</h2>
            <p className="text-gray-700">Data entered into this site is stored locally in your browser. No data is sent to any server.</p>
            <button
              className="block transition-all duration-300 transform border-[#0000ff] text-[#0000ff] hover:bg-[#0000ff] hover:text-white border-4 w-full px-4 py-2 mt-4"
              onClick={() => setIsModalOpen(true)}
            >
              Add Your First Record
            </button>
          </div>

          <div className="bg-white p-8 border border-gray-400 w-full md:w-1/2 mb-4">
            <h2 className="text-2xl mb-4">Your Consumption Records</h2>
            {
              records.length > 0 ? (
                <>
                  <table className="w-full border-collapse mb-4">
                    <thead>
                      <tr className="border-b">
                        <th className="p-2">Date</th>
                        <th className="p-2">Amount (grams)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        displayedRecords.map((record, index) => (
                          <tr key={index} className="hover:bg-gray-100">
                            <td className="p-2">{record.date}</td>
                            <td className="p-2">{record.amount}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                  <div className="flex justify-between">
                    <button
                      className="px-4 py-2 border border-gray-500"
                      disabled={currentPage === 1}
                      onClick={() => setCurrentPage(prev => prev - 1)}
                    >
                      Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button
                      className="px-4 py-2 border border-gray-500"
                      disabled={currentPage === totalPages}
                      onClick={() => setCurrentPage(prev => prev + 1)}
                    >
                      Next
                    </button>
                  </div>
                </>
              ) : (
                <div className="bg-gray-300 h-64 flex items-center justify-center">
                  <span className="text-gray-600">No records found. Add your first record!</span>
                </div>
              )
            }
          </div>

          <div className="bg-white p-8 border border-gray-400 col-span-2 w-full mb-4">
            <h2 className="text-2xl mb-4">Your Consumption Graph</h2>
            <div className="bg-gray-300 h-64 flex items-center justify-center h-max">
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={records}
                  margin={{}}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#0000ff" />
                  <XAxis dataKey="date" stroke="#0000ff" />
                  <YAxis stroke="#0000ff" />
                  <Tooltip />
                  <Line type="monotone" dataKey="amount" stroke="#0000ff" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white p-8 border border-gray-400 flex flex-col items-center w-full md:w-1/2 mb-4">
            <div className="bg-blue-100 p-5 rounded-full mb-4">
              {/* Placeholder icon for average */}
              <svg width={40} height={40} xmlns="http://www.w3.org/2000/svg">
                <circle cx={20} cy={20} r={20} fill="#2E7D32" />
              </svg>
            </div>
            <h2 className="text-2xl mb-2">Average Consumption</h2>
            <p className="text-gray-700 text-xl font-bold">
              {records.length > 0
                ? `${(records.reduce((acc, curr) => acc + parseFloat(curr.amount), 0) / records.length).toFixed(2)} grams`
                : "No data"}
            </p>
          </div>

          <div className="bg-white p-8 border border-gray-400 flex flex-col items-center w-full md:w-1/2 mb-4">
            <div className="bg-red-100 p-5 rounded-full mb-4">
              {/* Placeholder icon for days since last use */}
              <svg width={40} height={40} xmlns="http://www.w3.org/2000/svg">
                <rect x={10} y={10} width={20} height={20} fill="#2E7D32" />
              </svg>
            </div>
            <h2 className="text-2xl mb-2">Days Since Last Use</h2>
            <p className="text-gray-700 text-xl font-bold">
              {records.length > 0
                ? `${Math.floor((new Date().getTime() - new Date(records[records.length - 1].date).getTime()) / (1000 * 60 * 60 * 24))} days`
                : "No data"}
            </p>
          </div>

          <div className="bg-white p-8 border border-gray-400 flex flex-col items-center w-full md:w-1/2 mb-4">
            <h2 className="text-2xl mb-2">Biggest This Week</h2>
            <p className="text-gray-700 text-xl font-bold">
              {thisWeekRecords.length > 0 ? `${biggestThisWeek} grams` : "No data"}
            </p>
          </div>

          <div className="bg-white p-8 border border-gray-400 flex flex-col items-center w-full md:w-1/2 mb-4">
            <h2 className="text-2xl mb-2">Smallest This Week</h2>
            <p className="text-gray-700 text-xl font-bold">
              {thisWeekRecords.length > 0 ? `${smallestThisWeek} grams` : "No data"}
            </p>
          </div>

          <div className="bg-white p-8 border border-gray-400 flex flex-col items-center w-full md:w-1/2 mb-4">
            <h2 className="text-2xl mb-2">Consecutive Days of Use</h2>
            <p className="text-gray-700 text-xl font-bold">
              {"Data needed"}
            </p>
          </div>

          <div className="bg-white p-8 border border-gray-400 flex flex-col items-center w-full md:w-1/2 mb-4">
            <h2 className="text-2xl mb-2">Total This Month</h2>
            <p className="text-gray-700 text-xl font-bold">
              {thisMonthRecords.length > 0 ? `${totalThisMonth.toFixed(2)} grams` : "No data"}
            </p>
          </div>
        </div>
      </main>

      <footer className="mt-12 flex justify-between items-center">
        <p className="text-gray-600">© 2023 THCandMe. All rights reserved.</p>
        <div>
          <button
            className="transition-all duration-300 transform border-[#FF4136] text-[#FF4136] hover:bg-[#FF4136] hover:text-white border-4 px-4 py-2 mr-4"
            onClick={() => window.location.href = 'https://www.google.com'}
          >
            Leave This Site
          </button>
          <button
            className="transition-all duration-300 transform border-[#FFDC00] text-[#FFDC00] hover:bg-[#FFDC00] hover:text-white border-4 px-4 py-2"
            onClick={clearData}
          >
            Clear Data
          </button>
          <button
            className="transition-all duration-300 transform border-[#2ECC40] text-[#2ECC40] hover:bg-[#2ECC40] hover:text-white border-4 px-4 py-2 ml-4"
            onClick={addDemoData}
          >
            Add Demo Data
          </button>
        </div>
      </footer>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add Your Record"
        showSaveButton={true}
        onSave={handleSubmit}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex items-center mb-4">
            <label className="block text-gray-700 mb-2 flex-1" htmlFor="date">Date</label>
            <input className="border p-2 w-full flex-1" type="datetime-local" id="date" value={formData.date} onChange={e => setFormData({ ...formData, date: e.target.value })} />
            <button type="button" onClick={handleNowClick} className="ml-2 bg-gray-300 px-2 py-1">Now</button>
          </div>

          <div className="flex items-center mb-4">
            <label className="block text-gray-700 mb-2 flex-1" htmlFor="amount">Amount (grams)</label>
            <input className="border p-2 flex-2 w-1/3" type="number" id="amount" value={formData.amount} onChange={e => setFormData({ ...formData, amount: e.target.value })} step="0.01" placeholder='0.00' />
          </div>

          <div className="">
            <ul className="flex flex-row flex-wrap space-x-2">
              {
                records.slice(-3).map((record, index) => (
                  <li key={index}>
                    <button type="button" className="block transition-all duration-300 transform border-[#0000ff] text-[#0000ff] hover:bg-[#0000ff] hover:text-white border-4 px-4 py-2 mt-4 " onClick={() => handleRecentRecordClick(record)}>
                      {record.amount}g
                    </button>
                  </li>
                ))
              }
            </ul>
          </div>
        </form>
      </Modal>
    </div>
  );

}

export default App;
